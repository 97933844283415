import React from 'react';
import Helmet from 'react-helmet';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import Affiliate from '../components/affiliate'
import 'tachyons';
import '../styles/custom.tachyons.css';

export default (props) => (
  <React.Fragment>
    <Helmet>
      <body className="bg-near-white mid-gray" />
    </Helmet>
    <Navbar />
      <Affiliate />
      {props.children}
    <Footer />
  </React.Fragment>
)
