import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import {
  FaPinterestP,
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaGithub
} from 'react-icons/fa';
import 'tachyons';
// import { OutboundLink } from "gatsby-plugin-google-analytics";
import { OutboundLink } from "gatsby-plugin-google-gtag"



export default () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteTitle: title
            mailChimpUrl
            pinterest
            facebook
            twitter
            youtube
            github
          }
        }
      } 
    `}
    render={data => (
      <footer className="pa2 bg-dark-gray near-white pv5">
        <div className="flex flex-wrap justify-around w-100 mw9 center mb5">
          <div className="w-100 mw5 mb4">
            <span className="display f2">{data.site.siteMetadata.siteTitle}</span>
            <hr />
              <div className="w-100 flex justify-around items-center pv2">
              {data.site.siteMetadata.facebook && (
                <OutboundLink
                  className="near-white"
                  href={data.site.siteMetadata.facebook}
                >
                  <FaFacebookF />
                </OutboundLink>
              )}

              {data.site.siteMetadata.youtube && (
                <OutboundLink
                  className="near-white"
                  href={data.site.siteMetadata.youtube}
                >
                  <FaYoutube />
                </OutboundLink>
              )}

              {data.site.siteMetadata.github && (
                <OutboundLink
                  className="near-white"
                  href={data.site.siteMetadata.github}
                >
                  <FaGithub />
                </OutboundLink>
              )}

              {data.site.siteMetadata.pinterest && (
                <OutboundLink
                  className="near-white"
                  href={data.site.siteMetadata.pinterest}
                >
                  <FaPinterestP />
                </OutboundLink>
              )}

              {data.site.siteMetadata.twitter && (
                <OutboundLink className="near-white" href={data.site.siteMetadata.twitter}>
                  <FaTwitter />
                </OutboundLink>
              )}
            </div>
            <p>Best Sterilizers is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to Amazon.</p>
          </div>
          <div className="flex flex-column">
            <span className="near-white sans-serif f5 tracked mb3 db">WRITING BY {data.site.siteMetadata.siteTitle}</span>
            <Link to="/blog" className="near-white sans-serif f5 tracked pv1 db grow">ALL POSTS</Link>
            <Link to="/rss.xml" className="near-white sans-serif f5 tracked pv1 db grow">RSS FEED</Link>
          </div>
          <div className="flex flex-column">
            <span className="near-white sans-serif f5 tracked mb3 db">MORE ON {data.site.siteMetadata.siteTitle}</span>
            <Link to="/about" className="near-white sans-serif f5 tracked pv1 db grow">ABOUT US</Link>
            <a href={data.site.siteMetadata.mailChimpUrl} className="near-white sans-serif f5 tracked pv1 db grow">NEWS LETTER</a>
          </div>
        </div>
        <div className="w-100 mw9 center silver mb3">
          <div className="w-100 bb b--mid-gray mv3"></div>
          <div className="flex w-100 mw6 items-center justify-center justify-start-ns">
            <a href="/sitemap/sitemap-index.xml" className="silver sans-serif f5 tracked pv1 db mh1 hover-white">SITEMAP</a>
            <span className="mh1">|</span>
            <Link to="/privacy" className="silver sans-serif f5 tracked pv1 db mh1 hover-white">PRIVACY</Link>
            <span className="mh1">|</span>
            <a href="/terms" className="silver sans-serif f5 tracked pv1 db mh1 hover-white">TERMS OF SERVICE</a>
          </div>
        </div>
        <div className="w-100 mw9 silver center sans-serif f6">
          <p>Best Sterilizers © 2019-2024</p>
        </div>
      </footer>
    )} />
)
