import React from 'react';


export default () => (
    <div className=" near-black pv2 flex flex-column items-center">
        <p className="db sans-serif f10 tracked blue tc">
            We hope you love the products we recommend! Just so you know, we may earn an affiliate commission from the links on this site. We really appreciate your support!
            {/* <a href="/about" className="blue sans-serif f5 tracked pv1 db underline">Learn more</a> */}
        </p>
    </div>
)